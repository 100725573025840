import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography, Button } from '@mui/material';
import { getPresignedUrlRead } from '../../api';
import { getLandingMediaVideos, getMediaVideosByDocId } from '../../utils/firebaseFuntions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Doc1 from '../../assets/gallery/docId_1.png';
import Doc2 from '../../assets/gallery/docId_2.png';
import Doc3 from '../../assets/gallery/docId_3.png';
import Doc4 from '../../assets/gallery/docId_4.png';

const MediaGallery = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [loadingDocId, setLoadingDocId] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const docIds = [
    '4mlcrMBOsj6nE8B5zP1j',
    'DhekMG1pT5jf0NYdeNQq',
    'h3YO7lkLcqO4z3URzCHm',
    'zvvnR1Wg3ttvD4sVrikr',
  ];

  const thumbnails = [Doc1, Doc2, Doc3, Doc4];

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkMobile = () => {
      const ua = navigator.userAgent;
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua));
    };

    checkMobile();
    getVideo()
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // const fetchVideo = async () => {
  //   for (let i = 0; i < docIds.length; i++) {
  //     // Correct the loop condition
  //     const docId = docIds[i];
  //     if (loadingDocId === docId || videoUrls[docId]) continue; // Skip if already loading or fetched

  //     setLoadingDocId(docId);

  //     try {
  //       const mediaItem = await getMediaVideosByDocId(docId);
  //       const media = mediaItem?.media_url;

  //       if (media) {
  //         const folderName = media.substring(0, media.lastIndexOf('/'));
  //         const fileName = media.substring(media.lastIndexOf('/') + 1);

  //         const presignedUrl = await getPresignedUrlRead(folderName, fileName);
  //         setVideoUrls((prev) => ({ ...prev, [docId]: presignedUrl?.data }));
  //       }
  //     } catch (error) {
  //       console.error('Error fetching video:', error);
  //     } finally {
  //       setLoadingDocId(null);
  //     }
  //   }
  // };

  const getVideo = async () => {
    try {
      const res = await getLandingMediaVideos();

      // Use Promise.all to handle asynchronous operations inside map
      const formattedRes = await Promise.all(
        res.map(async (item) => {
          const [folderName, fileName] = item.media_url.split('/');

          // Get the video URL
          const preVideoSignedUrl = await getPresignedUrlRead(folderName, fileName);

          // Modify folderName and filename to fetch the thumbnail
          const thumbnailFolderName = '/public_media_video_thumbnails';
          const thumbnailFileName = fileName.replace(/\.\w+$/, '.png'); // Replace the extension with .png
          const preThumbnailSignedUrl = await getPresignedUrlRead(
            thumbnailFolderName,
            thumbnailFileName,
          );
          return {
            folderName,
            fileName,
            videoUrl: preVideoSignedUrl?.data,
            thumbnailUrl: preThumbnailSignedUrl?.data,
          };
        }),
      );
      console.log(formattedRes)
      setVideoUrls(formattedRes)
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  const handlePlay = (videoElement) => {
    if (currentVideo && currentVideo !== videoElement) {
      currentVideo.pause();
    }
    setCurrentVideo(videoElement);
  };

  const handleVideoError = (e) => {
    console.error('Video playback error:', e);
  };

  return (
    <div style={{ padding: '0 6%', textAlign: 'center' }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          color: '#7a5e34',
          marginBottom: '50px',
          fontSize: '30px',
          textAlign: 'center',
        }}
      >
        {t('media_gallery')}
      </Typography>

      <Grid container spacing={3}>
        {videoUrls.map((url, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <video
              src={url?.videoUrl}
              controls
              playsInline
              preload="none"
              poster={url?.thumbnailUrl}
              style={{
                maxWidth: '80%',
                minWidth: '80%',
                minHeight: '100%',
                objectFit: 'contain',
                borderRadius: '15px',
                border: '1px solid black',
              }}
              onPlay={(e) => handlePlay(e.target)}
              onError={handleVideoError}
            >
              <track kind="captions" src="" srcLang="en" label="English" />
              Your browser does not support the video tag.
            </video>
          </Grid>
        ))}
      </Grid>

      <Box mt={3} textAlign="center">
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#B99744',
            color: 'black',
            fontWeight: 'bold',
            mb: 2,
            '&:hover': {
              bgcolor: '#9F8239',
            },
          }}
          onClick={() => navigate('/media')}
        >
          {t('view_more')}
        </Button>
      </Box>
    </div>
  );
};
export default MediaGallery;
