import React from 'react';
import { Grid, Box, Typography, Link as MuiLink, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000',
        color: '#fff',
        gap: isSmallScreen ? '1rem' : '2%',
        padding: isSmallScreen ? '0.5rem 0' : '1rem 0',
        flexWrap: isSmallScreen ? 'wrap' : 'nowrap',
      }}
    >
      {window.innerWidth > 600 ? (
        <>
          <a href="/about" style={{ textDecoration: 'none', mx: 2 }}>
            <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
              {t('about')}
            </Typography>
          </a>
          <a href="/privacy-policy" style={{ textDecoration: 'none', mx: 2 }}>
            <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
              {t('privacy_policy')}
            </Typography>
          </a>
          <a href="/terms" style={{ textDecoration: 'none', mx: 2 }}>
            <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
              {t('terms_condition')}
            </Typography>
          </a>
          <a href="/disclaimer" style={{ textDecoration: 'none', mx: 2 }}>
            <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
              {t('disclaimer')}
            </Typography>
          </a>
          <Link to="/download" style={{ textDecoration: 'none', mx: 2 }}>
            <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
              {t('downloads')}
            </Typography>
          </Link>
          <Link to="/blog" style={{ textDecoration: 'none', mx: 2 }}>
            <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
              {t('blog')}
            </Typography>
          </Link>
          <Grid item xs={12}>
            <a href="tel:011-411-69034" style={{ textDecoration: 'none' }}>
              <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
                {t('helpdesk_num')} 011-411-69034
              </Typography>
            </a>
          </Grid>
        </>
      ) : (
        <>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={4} display="flex" justifyContent="end">
              <a href="/privacy-policy" style={{ textDecoration: 'none' }}>
                <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
                  {t('privacy_policy')}
                </Typography>
              </a>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
              <a href="/terms" style={{ textDecoration: 'none' }}>
                <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
                  {t('terms_condition')}
                </Typography>
              </a>
            </Grid>

            <Grid item xs={4} display="flex" justifyContent="start">
              <a href="/disclaimer" style={{ textDecoration: 'none' }}>
                <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
                  {t('disclaimer')}
                </Typography>
              </a>
            </Grid>

            <Grid xs={12} display="flex" justifyContent="center">
              <div className="mx-2">
                <a href="/about" style={{ textDecoration: 'none', mx: 2 }}>
                  <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
                    {t('about')}
                  </Typography>
                </a>
              </div>
              <div className="mx-2">
                <Link to="/download" style={{ textDecoration: 'none', mx: 2 }}>
                  <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
                    {t('downloads')}
                  </Typography>
                </Link>
              </div>
              <div className="mx-2">
                <Link to="/blog" style={{ textDecoration: 'none', mx: 2 }}>
                  <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
                    {t('blog')}
                  </Typography>
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <a href="tel:011-411-69034" style={{ textDecoration: 'none' }}>
                <Typography color="#fff" fontWeight="500" sx={{ my: 1, fontSize: '12px' }}>
                  {t('helpdesk_num')} 011-411-69034
                </Typography>
              </a>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
