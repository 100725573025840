import firebase from '../firebase';

export const getStates = async () => {
  let data = [];
  try {
    const db = firebase.firestore();
    const statesCollection = db.collection('state_master');
    const querySnapShot = await statesCollection.get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }

    // Sort the data based on `state_name`
    data.sort((a, b) => a.state_name.localeCompare(b.state_name));

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getDistrict = async (stateCode) => {
  let data = [];
  try {
    const db = firebase.firestore();
    const districtCollection = db.collection('state_district_master');
    const querySnapShot = await districtCollection.where('state_code', '==', stateCode).get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }

    data.sort((a, b) => a.district_name.localeCompare(b.district_name));

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getBlocks = async (districtCode) => {
  let data = [];
  try {
    const db = firebase.firestore();
    const blockCollection = db.collection('district_block_master');
    const querySnapShot = await blockCollection.where('district_code', '==', districtCode).get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }

    data.sort((a, b) => a.block_name.localeCompare(b.block_name));

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getGps = async (blockCode) => {
  let data = [];
  try {
    const db = firebase.firestore();
    const gpCollection = db.collection('block_gp_master');
    const querySnapShot = await gpCollection.where('block_code', '==', blockCode).get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }

    data.sort((a, b) => a.gp_name.localeCompare(b.gp_name));

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getMinistryDepartment = async (ministryCode) => {
  let data = [];
  try {
    const db = firebase.firestore();
    const ministryDepartmentCollection = db.collection('ministry_department_master');
    const querySnapShot = await ministryDepartmentCollection
      .where('ministry_code', '==', ministryCode)
      .get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getMinistryUnits = async (ministryDepartmentCode) => {
  let data = [];
  try {
    const db = firebase.firestore();
    const ministryUnitCollection = db.collection('ministry_unit_master');
    const querySnapShot = await ministryUnitCollection
      .where('ministry_department_code', '==', ministryDepartmentCode)
      .get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getUlbs = async (districtCode) => {
  let data = [];
  try {
    const db = firebase.firestore();
    const ulbCollection = db.collection('district_ulb_master');
    const querySnapShot = await ulbCollection.where('district_code', '==', districtCode).get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }

    data.sort((a, b) => a.ulb_name.localeCompare(b.ulb_name));

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getRoles = async () => {
  let data = [];
  try {
    const db = firebase.firestore();
    const roleCollection = db.collection('role_master');
    const querySnapShot = await roleCollection.get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }
    data = data.filter((role) => role.role_id !== 1);
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getMinistry = async () => {
  let data = [];
  try {
    const db = firebase.firestore();
    const ministryCollection = db.collection('ministry_master');
    const querySnapShot = await ministryCollection.get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getUserByEmail = async (email) => {
  let data = [];
  try {
    const db = firebase.firestore();
    const userCollection = db.collection('users');
    const querySnapShot = await userCollection.where('email', '==', email).limit(1).get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }
    return data[0];
  } catch (e) {
    console.log(e);
  }
};

export const getUserDetailsByEmail = async (email) => {
  let data = [];
  try {
    const db = firebase.firestore();
    const userCollection = db.collection('user_details');
    const querySnapShot = await userCollection.where('email', '==', email).limit(1).get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }
    return data[0];
  } catch (e) {
    console.log(e);
  }
};

export const getCurrentToken = async () => {
  return await firebase.auth().currentUser.getIdToken(true);
};

export const getUserByID = async (id) => {
  try {
    const db = firebase.firestore();
    const userCollection = db.collection('users');
    const docSnap = await userCollection.doc(id).get();

    if (docSnap.exists) {
      return { id: docSnap.id, ...docSnap.data() }; // Return the document data with the ID
    } else {
      console.log('No such document!');
      return null; // Return null if the document doesn't exist
    }
  } catch (e) {
    console.error('Error fetching user by ID:', e);
    return null;
  }
};

export const getMediaImages = async () => {
  let data = [];
  try {
    const db = firebase.firestore();
    const mediaCollection = db.collection('moderated_event_media');
    const querySnapShot = await mediaCollection
      .where('media_category', '==', 'image')
      .limit(10)
      .get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }
    return data;
  } catch (e) {
    console.error('Error fetching user by ID:', e);
    return null;
  }
};

export const getMediaImagesPaginated = async (lastDocument = null) => {
  const data = [];
  try {
    const db = firebase.firestore();
    const mediaCollection = db.collection('moderated_event_media');
    let query = mediaCollection.where('media_category', '==', 'image').limit(20);

    // Add startAfter if a lastDocument is provided
    if (lastDocument) {
      query = query.startAfter(lastDocument);
    }

    const querySnapShot = await query.get();
    const lastDoc = querySnapShot.docs[querySnapShot.docs.length - 1]; // Get the last document

    querySnapShot.forEach((doc) => {
      data.push(doc.data());
    });

    return { data, lastDoc };
  } catch (e) {
    console.error('Error fetching images with pagination:', e);
    return null;
  }
};

export const getMediaVideosPaginated = async (lastDocument = null) => {
  const data = [];
  try {
    const db = firebase.firestore();
    const mediaCollection = db.collection('moderated_event_media');
    let query = mediaCollection.where('media_category', '==', 'video').limit(10);

    // Add startAfter if a lastDocument is provided
    if (lastDocument) {
      query = query.startAfter(lastDocument);
    }

    const querySnapShot = await query.get();
    const lastDoc = querySnapShot.docs[querySnapShot.docs.length - 1]; // Get the last document

    querySnapShot.forEach((doc) => {
      data.push(doc.data());
    });

    return { data, lastDoc };
  } catch (e) {
    console.error('Error fetching images with pagination:', e);
    return null;
  }
};

export const getMediaVideos = async () => {
  const data = [];
  try {
    const db = firebase.firestore();
    const mediaCollection = db.collection('moderated_event_media');
    let query = mediaCollection.where('media_category', '==', 'video').limit(4);

    const querySnapShot = await query.get();

    querySnapShot.forEach((doc) => {
      data.push(doc.data());
    });

    return { data };
  } catch (e) {
    console.error('Error fetching images with pagination:', e);
    return null;
  }
};

export const getLandingMediaVideos = async () => {
  const data = [];
  try {
    const db = firebase.firestore();
    const mediaCollection = db.collection('moderated_event_media');
    let query = mediaCollection
      .where('media_category', '==', 'video')
      .where('landing_page', '==', true)
      .limit(4);

    const querySnapShot = await query.get();

    querySnapShot.forEach((doc) => {
      data.push(doc.data());
    });

    return data;
  } catch (e) {
    console.error('Error fetching images with pagination:', e);
    return null;
  }
};

export const getTweetIDs = async () => {
  const db = firebase.firestore();
  const tweetCollection = db.collection('tweets');
  const querySnapshot = await tweetCollection.get();
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getBlogs = async () => {
  const db = firebase.firestore();
  const blogCollection = db.collection('blogs');
  const querySnapshot = await blogCollection.get();
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getBlogByID = async (id) => {
  try {
    const db = firebase.firestore();
    const blogCollection = db.collection('blogs');
    const docSnap = await blogCollection.doc(id).get();

    if (docSnap.exists) {
      return { id: docSnap.id, ...docSnap.data() }; // Return the document data with the ID
    } else {
      console.log('No such document!');
      return null; // Return null if the document doesn't exist
    }
  } catch (e) {
    console.error('Error fetching user by ID:', e);
    return null;
  }
};

export const getMediaVideosByDocId = async (id) => {
  try {
    const db = firebase.firestore();
    const blogCollection = db.collection('moderated_event_media');
    const docSnap = await blogCollection.doc(id).get();

    if (docSnap.exists) {
      return { id: docSnap.id, ...docSnap.data() }; // Return the document data with the ID
    } else {
      console.log('No such document!');
      return null; // Return null if the document doesn't exist
    }
  } catch (e) {
    console.error('Error fetching user by ID:', e);
    return null;
  }
};

export const getEvents = async (filters = {}) => {
  try {
    const db = firebase.firestore();
    const eventsCollection = db.collection('events'); // Assuming 'events' is your collection name

    // Start building the query
    let query = eventsCollection;

    // Dynamically apply filters
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        query = query.where(key, '==', filters[key]);
      }
    });

    // Fetch the documents based on the constructed query
    const snapshot = await query.get();
    const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })); // Map each document to include its ID

    return data; // Return the array of document data
  } catch (e) {
    console.error('Error fetching filtered events:', e);
    return []; // Return an empty array in case of an error
  }
};

export const getEventMaster = async () => {
  try {
    const db = firebase.firestore();
    const eventsCollection = db.collection('event_master');

    // Fetch all documents in the collection
    const snapshot = await eventsCollection.get();
    const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })); // Map each document to include its ID

    return data; // Return the array of document data
  } catch (e) {
    console.error('Error fetching events:', e);
    return []; // Return an empty array in case of an error
  }
};

export const getAIQuestions = async () => {
  let data = [];
  try {
    const db = firebase.firestore();
    const aiCollection = db.collection('ai_questions');
    const querySnapShot = await aiCollection.get();
    if (!querySnapShot.empty) {
      querySnapShot.forEach((doc) => {
        let newdata = doc.data();
        data.push(newdata);
      });
    }

    return data;
  } catch (e) {
    console.log(e);
  }
};
