import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from '../locales/english.json';
import hiTranslations from '../locales/hindi.json';
import pbTranslations from '../locales/punjabi.json';
import mrTranslations from '../locales/marathi.json';
import knTranslations from '../locales/kannada.json';
import bgTranslations from '../locales/bengali.json';
import tlTranslations from '../locales/telugu.json';
import odTranslations from '../locales/odia.json';
import mlTranslations from '../locales/malyalam.json';
import gjTranslations from '../locales/gujrati.json';
import tmTranslations from '../locales/tamil.json';
import asTranslations from '../locales/assamese.json';
import malTranslations from '../locales/malyalam.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // using the language detector plugin
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      tm: {
        translation: tmTranslations,
      },
      as: {
        translation: asTranslations,
      },
      hi: {
        translation: hiTranslations,
      },
      pb: {
        translation: pbTranslations,
      },
      mr: {
        translation: mrTranslations,
      },
      kn: {
        translation: knTranslations,
      },
      bg: {
        translation: bgTranslations,
      },
      tl: {
        translation: tlTranslations,
      },
      od: {
        translation: odTranslations,
      },
      ml: {
        translation: mlTranslations,
      },
      gj: {
        translation: gjTranslations,
      },
    },
    lng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
