import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import landingBg from '../../assets/images/landing/landingBg.png';
import landingBook from '../../assets/images/landing/book.png';
import landingMob from '../../assets/images/landing/landing_mob.png';
import { useTranslation, Trans } from 'react-i18next';

export default function LandingBanner({ eventCount, participationCount, preambleRead }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundImage: `url(${landingBg})`,
        backgroundSize: window.innerWidth < 600 ? 'contain' : 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        //changed
        minHeight: window.innerWidth > 600 ? '100vh' : '77vh',
        width: '100vw',
        textAlign: 'center',
        color: 'goldenrod',
        zIndex: 1,
        paddingTop: window.innerWidth > 600 ? '0%' : '10%',
      }}
      // onClick={() => navigate('/public-participation')}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
        <Box
          component="img"
          src={landingBook}
          alt="Book Image"
          sx={{
            width: { xs: '27%', md: '13%' },
            marginTop: { xs: '10px', md: '40px' },
          }}
        />
        <Typography
          variant="h2"
          color={window.innerWidth > 600 ? 'white' : '#B99744'}
          fontWeight="bold"
          sx={{
            my: 2,
            pt: 3,
            fontSize: {
              xs: '1.8rem', // Full width on small devices
              md: '2.5rem', // 50% width on medium and larger devices
            },

            // paddingBottom: window.innerWidth > 600 ? '0' : '20%',
            marginLeft: '10px',
            marginRight: '10px',
          }}
        >
          {t('landing_heading')}
        </Typography>

        <Box
          sx={{
            width: {
              xs: '100%', // Full width on small devices
              md: '50%', // 50% width on medium and larger devices
            },
          }}
        >
          <Typography
            variant="h5"
            color="#B99744"
            sx={{
              mb: 2,
              padding: '20px',
            }}
          >
            <Trans i18nKey="landing_text" components={{ sup: <sup /> }} />
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{
            bgcolor: '#B99744',
            color: 'black',
            fontWeight: 'bold',
            // mb: 2,
            mb: 1,
            padding: 2,
            transition: 'transform 0.2s ease, background-color 0.2s ease',
            '&:hover': {
              bgcolor: '#9F8239',
              transform: 'scale(1.05)',
            },
          }}
          onClick={() => navigate('/public-participation')}
        >
          {t('participate')}
        </Button>
        <Typography variant="p" sx={{ fontSize: '0.8rem' }}>
          {t('read_preamble')}
        </Typography>
        <br />
        {/* <Typography
          variant="p"
          sx={{ fontSize: window.width > 760 ? '1.6rem' : '1.3rem', color: window.width > 760 ? 'white' : '#B99744', mt: 1 }}
        >
          {preambleRead} {t('citizen_read_preamble')}
        </Typography> */}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {/* {window.innerWidth > 600 && (
          <Box>
            <Typography
              variant="h3"
              color="#B99744"
              fontWeight="bold"
              sx={{
                fontSize: {
                  xs: '1.2rem', // Full width on small devices
                  md: '1.5rem', // 50% width on medium and larger devices
                },
              }}
            >
              {eventCount || 0}
            </Typography>
            <Typography
              variant="h6"
              color="#B99744"
              sx={{
                fontSize: {
                  xs: '1.2rem', // Full width on small devices
                  md: '1.5rem', // 50% width on medium and larger devices
                },
              }}
            >
              {t('total_events')}
            </Typography>
          </Box>
        )} */}

        {/* <iframe
          src="https://my.spline.design/untitled-5c5a14c6792beff2bfc4f494dea39b3d/"
          frameborder="0"
          width="30%"
          height="500px"
          title="3D model viewer"
        ></iframe> */}
        {/* {window.innerWidth > 600 && (
          <Box>
            <Typography
              variant="h3"
              color="#B99744"
              fontWeight="bold"
              sx={{
                fontSize: {
                  xs: '1.2rem', // Full width on small devices
                  md: '1.5rem', // 50% width on medium and larger devices
                },
              }}
            >
              {participationCount || 0}
            </Typography>
            <Typography
              variant="h6"
              color="#B99744"
              sx={{
                fontSize: {
                  xs: '1.2rem', // Full width on small devices
                  md: '1.5rem', // 50% width on medium and larger devices
                },
              }}
            >
              {t('total_participants')}
            </Typography>
          </Box>
        )} */}
      </Box>
    </Box>
  );
}
