import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function VideoSection() {
  const { t, i18n } = useTranslation();
  return (
    <div style={{ padding: '0 6%', textAlign: 'center', marginBottom: '5%' }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          color: '#7a5e34',
          marginBottom: '50px',
          fontSize: '30px',
          textAlign: 'center',
        }}
      >
        {t('video_section_title')}
      </Typography>
      <Grid item xs={12}>
        <div className="flag_video">
          <iframe
            width="600"
            min-height="180"
            height={window.innerWidth > 767 ? '400' : '200'}
            className="img-fluid "
            src="https://www.youtube.com/embed/CNDZlorrzCk?si=HuENWLYfbKXeljOo"
            title="Anthem"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ borderRadius: '10px' }}
          />
        </div>
      </Grid>
    </div>
  );
}
