import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import ProfileReducer from './profile/ProfileReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  ProfileReducer
});

export default RootReducers;
