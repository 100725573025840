import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Box,
  CardContent,
  Typography,
  CircularProgress,
  InputAdornment,
  Card,
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import annyang from 'annyang';
import { getAudioFromElevenLabs, getPresignedUrlRead, saveQuestion } from '../../api';
import { getAIQuestions } from '../../utils/firebaseFuntions';
import { useTranslation } from 'react-i18next';

const ChatBot = ({ docId }) => {
  const { t } = useTranslation();
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [suggestiveQuestion, setSuggestiveQuestion] = useState([]);

  const chatContainerRef = useRef(null);

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const playAudio = async (audioUrl) => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }

    const newAudio = new Audio(audioUrl);
    setCurrentAudio(newAudio);

    try {
      await newAudio.play();
      newAudio.onplay = () => setLoading(false);
      newAudio.onended = () => setCurrentAudio(null);
    } catch (error) {
      console.error('Error playing audio:', error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    const currentUserInput = userInput;
    setUserInput('');
    setLoading(true);

    const newChat = [...chatHistory, { message: currentUserInput, user: true }];
    setChatHistory([...newChat, { message: 'loading', loading: true }]);

    try {
      const response = await axios.post(
        'https://asia-south1-samvidhan-75.cloudfunctions.net/ai-api',
        {
          history: chatHistory.map((entry) => `${entry.user ? 'Human' : 'Bot'}: ${entry.message}`),
          query: currentUserInput,
        },
      );

      const botResponse = response.data.answer.replace(/openai/gi, 'Tagbin');
      const res = await saveQuestion(currentUserInput, botResponse, docId);
      if (res.status === 200) {
        const audioUrl = await getAudioFromElevenLabs(botResponse);

        await playAudio(audioUrl);

        setChatHistory((prevChat) => [
          ...prevChat.filter((msg) => !msg.loading),
          { message: botResponse, user: false },
        ]);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error during chat interaction:', error);
      setChatHistory((prevChat) => [
        ...prevChat.filter((msg) => !msg.loading),
        { message: 'I encountered an error. Please try again.', user: false },
      ]);
      setLoading(false);
    }
  };

  const handleSpeechToText = () => {
    if (annyang) {
      annyang.start();
      setUserInput('Listening...');
      annyang.addCallback('result', (phrases) => {
        const transcript = phrases[0];
        setUserInput(transcript);
        annyang.abort();
      });
    } else {
      console.error('Speech recognition not supported in this browser.');
    }
  };

  const getQuestions = async () => {
    const res = await getAIQuestions();
    setSuggestiveQuestion(res);
  };

  const handleSuggestiveQuestion = async (data) => {
    setLoading(true);
    try {
      const parts = data?.audioUrl.split('/');
      const folderName = parts[0];
      const fileName = parts[1];

      const response = await saveQuestion(data?.question, data?.botResponse, docId);

      if (response?.status === 200) {
        const res = await getPresignedUrlRead(folderName, fileName);
        if (res.status === 200) {
          await playAudio(res.data);

          setChatHistory((prevChat) => [
            ...prevChat,
            { message: data.question, user: true },
            { message: data.botResponse, user: false },
          ]);
        }
      }
    } catch (error) {
      console.error('Error handling suggestive question:', error);
      setChatHistory((prevChat) => [
        ...prevChat,
        { message: 'Error playing the response. Please try again.', user: false },
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card
        sx={{
          width: { sm: '100%', md: '80%' },
          minHeight: '65vh',
          maxHeight: '65vh',
          backgroundColor: '#D6C2A8',
          border: '2px solid #5A3E00',
          margin: 0,
          position: 'relative',
        }}
      >
        <CardContent>
          <Box
            ref={chatContainerRef}
            sx={{
              borderRadius: '8px',
              backgroundColor: '#D6C2A8',
              maxHeight: '400px', // Ensure there’s a max height to enable scrolling
              overflowY: 'auto',
            }}
          >
            {chatHistory.length < 1 && (
              <Box
                textAlign="center"
                sx={{ marginTop: '4%', maxHeight: '30vh', overflow: 'scroll' }}
              >
                <Typography
                  fontSize={16}
                  sx={{ color: '#5A3E00', fontWeight: '700', marginBottom: '12px' }}
                >
                  Ask questions such as:
                </Typography>
                {suggestiveQuestion.map((data, index) => (
                  <Box
                    key={index}
                    sx={{
                      borderRadius: '16px',
                      border: '1px solid #5A3E00',
                      padding: '4px',
                      marginBottom: '12px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleSuggestiveQuestion(data)}
                  >
                    <Typography fontSize={14} sx={{ color: '#5A3E00', fontWeight: '700' }}>
                      {data?.question}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            {chatHistory.map((message, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: message.user ? 'flex-end' : 'flex-start',
                  mb: 1,
                }}
              >
                <Box
                  sx={{
                    p: 1,
                    borderRadius: '12px',
                    maxWidth: '75%',
                    backgroundColor: message.user ? '#cfe8fc' : '#e8f5e9',
                    color: 'black',
                  }}
                >
                  {message.loading ? <CircularProgress size={20} /> : message.message}
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              backgroundColor: '#D6C2A8',
              pt: 2,
              pb: 2,
              left: 20,
              right: 20,
            }}
          >
            <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '8px' }}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder={t('type_your_message')}
                value={userInput}
                onChange={handleUserInput}
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <Button
                          variant="ghost"
                          color="primary"
                          type="submit"
                          startIcon={<SendIcon />}
                          sx={{
                            minWidth: 0,
                            padding: 0,
                          }}
                        />
                        <Button
                          variant="ghost"
                          color="secondary"
                          onClick={handleSpeechToText}
                          startIcon={<MicIcon />}
                          sx={{
                            minWidth: 0,
                            padding: 0,
                          }}
                        />
                      </Box>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  backgroundColor: '#FAEBDC',
                }}
              />
            </form>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ChatBot;
