import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';
import 'firebase/compat/functions';
import { getAuth } from 'firebase/auth';
// import { staging_config } from './config/staging';
import { prod_config } from './config/production';
import { getFirestore } from 'firebase/firestore';

const  getFunctions  = require('firebase/functions');
const firebaseConfig = prod_config;
const app = firebase.initializeApp(firebaseConfig);
firebase.database();
firebase.storage();
firebase.analytics();
firebase.firestore();
export const db = getFirestore(app);
export const firestore = firebase.firestore();
// export const functions = getFunctions(app);
export const auth = getAuth(app);

export default firebase;
