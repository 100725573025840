import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import headerLogo from '../../../assets/logos/headerLogo.png';
import languageLogo from '../../../assets/logos/languageLogo.png';
import ambedkarIconImage from '../../../assets/images/landing/ambedkarIconImage.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FeatherIcon from 'feather-icons-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { updateProfile } from '../../../redux/profile/action';
import firebase from '../../../firebase';
import Logo2 from '../../../assets/logos/logo2.png';
import { Close } from '@mui/icons-material';

const LogoIcon = () => {
  return (
    <Link underline="none" to="/" style={{ display: 'flex', alignItems: 'center' }}>
      <img src={headerLogo} style={{ height: '50px' }} alt="LOGO" />
      <div
        style={{
          height: '50px',
          width: '1px',
          backgroundColor: '#fff',
          margin: '0 10px',
        }}
      />
      <img src={Logo2} style={{ height: '80px' }} alt="LOGO" />
    </Link>
  );
};

const Header = ({ sx }) => {
  const { t, i18n } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loginAnchorEl, setLoginAnchorEl] = useState(null);

  const profile = useSelector((state) => state.ProfileReducer);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(updateProfile({ logged_in: false, user: {}, user_details: {}, token: '' }));
    firebase.auth().signOut();
    navigate('/auth/login');
    localStorage.removeItem('token');
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const languages = [
    { code: 'en', label: 'English' },
    { code: 'hi', label: 'हिन्दी' },
    { code: 'pb', label: 'Punjabi' },
    { code: 'mr', label: 'Marathi' },
    { code: 'kn', label: 'Kannada' },
    { code: 'bg', label: 'Bengali' },
    { code: 'tl', label: 'Telugu' },
    { code: 'od', label: 'Odia' },
    { code: 'ml', label: 'Malyalam' },
    { code: 'gj', label: 'Gujarati' },
    { code: 'tm', label: 'Tamil' },
    { code: 'as', label: 'Assamese' },
  ];

  const handleLoginClick = (event) => {
    setLoginAnchorEl(event.currentTarget);
  };

  const handleLoginClose = () => {
    setLoginAnchorEl(null);
  };

  const loginOptions = [
    { label: t('nodal_officer'), link: '/dashboard' },
    { label: t('gov_dep'), link: '/dashboard' },
    { label: t('urban_body'), link: '/dashboard' },
    { label: t('college'), link: '/dashboard' },
    { label: t('entities'), link: '/dashboard' },
  ];

  const location = useLocation();

  const [anchorEl4, setAnchorEl4] = useState(null);

  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const menuItems = (
    <>
      <a href="/" style={{ textDecoration: 'none' }}>
        <Typography
          color={{ xs: '#dc7327', md: 'white' }}
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, my: 2, '&:hover': { fontWeight: 'bold' } }}
        >
          {t('home')}
        </Typography>
      </a>

      {/* <Link to="/constitution" style={{ textDecoration: 'none' }}> */}
      <a href="/constitution" style={{ textDecoration: 'none' }}>
        <Typography
          color={{ xs: '#dc7327', md: 'white' }}
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, my: 2, '&:hover': { fontWeight: 'bold' } }}
        >
          {t('constitution')}
        </Typography>
      </a>
      {/* </Link> */}
      <a href="/preambles" style={{ textDecoration: 'none' }}>
        <Typography
          color={{ xs: '#dc7327', md: 'white' }}
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, my: 2, '&:hover': { fontWeight: 'bold' } }}
        >
          {t('preamble')}
        </Typography>
      </a>
      <a href="/making-of-constitution" style={{ textDecoration: 'none' }}>
        <Typography
          color={{ xs: '#dc7327', md: 'white' }}
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, my: 2, '&:hover': { fontWeight: 'bold' } }}
        >
          {t('making_constitution')}
        </Typography>
      </a>

      <a
        // here
        href="/ask-baba-sahib"
        style={{ textDecoration: 'none' }}
      >
        <Typography
          color={{ xs: '#dc7327', md: 'white' }}
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, my: 2, '&:hover': { fontWeight: 'bold' } }}
        >
          {t('know_your_constitution')}
        </Typography>
      </a>
      <a href="/download" style={{ textDecoration: 'none' }}>
        <Typography
          color={{ xs: '#dc7327', md: 'white' }}
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, my: 2, '&:hover': { fontWeight: 'bold' } }}
        >
          {t('downloads')}
        </Typography>
      </a>
      <>
        <Typography
          color={{ xs: '#dc7327', md: 'white' }}
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, my: 2, cursor: 'pointer', '&:hover': { fontWeight: 'bold' } }}
          onClick={handleLoginClick}
        >
          {t('events')}
          <ArrowDropDownIcon sx={{ ml: 0.5 }} />
        </Typography>
        <Menu anchorEl={loginAnchorEl} open={Boolean(loginAnchorEl)} onClose={handleLoginClose}>
          {loginOptions.map((option) => (
            <MenuItem
              key={option.label}
              component={Link}
              to={option.link}
              onClick={handleLoginClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>

      {profile?.logged_in ? (
        <Box display="flex" alignItems="center">
          <a href="/event-reports" style={{ textDecoration: 'none' }}>
            <Typography color={{ xs: '#dc7327', md: 'white' }} variant="h5" fontWeight="500">
              {t('dashboard')}
            </Typography>
          </a>
          <Button
            aria-label="menu"
            color="inherit"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleClick4}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'flex',
                  },
                  alignItems: 'center',
                }}
              >
                <AccountCircleIcon />
              </Box>
            </Box>
          </Button>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl4}
            keepMounted
            open={Boolean(anchorEl4)}
            onClose={handleClose4}
            sx={{
              '& .MuiMenu-paper': {
                width: '385px',
                right: 0,
                top: '70px !important',
              },
              '& .MuiList-padding': {
                p: '30px',
              },
            }}
          >
            {/* <Typography color="textSecondary" variant="h5" fontWeight="400" sx={{ mb: 3 }}>
            Designation - {profile?.user_details?.designation}
          </Typography> */}
            <Link
              style={{
                textDecoration: 'none',
              }}
              to="/auth/login"
            >
              <Button
                sx={{
                  bgcolor: '#B99744',
                  color: 'black',
                  fontWeight: 'bold',
                  width: '100%',
                  '&:hover': {
                    bgcolor: '#9F8239',
                  },
                }}
                variant="contained"
                color="primary"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Link>
          </Menu>
        </Box>
      ) : (
        <>
          <a
            // here
            href="/auth/login"
            style={{ textDecoration: 'none' }}
          >
            <Typography
              color={{ xs: '#dc7327', md: 'white' }}
              variant="h5"
              fontWeight="500"
              sx={{ ml: 1, my: 2, '&:hover': { fontWeight: 'bold' } }}
            >
              {t('dashboard_login')}
            </Typography>
          </a>
        </>
      )}

      <div className="lang_div">
        <IconButton onClick={handleClick}>
          <img src={languageLogo} alt="Language Icon" style={{ width: 24, height: 24 }} />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {languages.map((lang) => (
            <MenuItem
              key={lang.code}
              onClick={() => {
                changeLanguage(lang.code);
                handleClose();
              }}
            >
              {lang.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          ...sx,
          backgroundColor: '#000000',
          boxShadow: '0px 4px 33.8px 0px #00000033',
          zIndex: 100,
        }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSidebar}
            sx={{ mr: 2, display: { xs: 'block', md: 'none' } }} // HAMBURGER MENU FOR MOBILE
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <LogoIcon />
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' }, // MENU ITEMS ONLY VISIBLE ON DESKTOP
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '75%',
              }}
            >
              {menuItems}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* SIDEBAR DRAWER FOR MOBILE VIEW */}
      <Drawer anchor="left" open={sidebarOpen} onClose={toggleSidebar}>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
          }}
          onClick={() => setSidebarOpen(false)}
        >
          <Close />
        </IconButton>
        <Box
          sx={{ width: 250, m: 2 }}
          role="presentation"
          // onClick={toggleSidebar}
          // onKeyDown={toggleSidebar}
        >
          <List>{menuItems}</List>
        </Box>
      </Drawer>
    </>
  );
};

Header.propTypes = {
  sx: PropTypes.object,
};

export default Header;
