import React, { useEffect, useState } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import Navbar from '../../layouts/full-layout/header/homeHeader';
import { useNavigate } from 'react-router';
import LandingBanner from '../../components/carousel/LandingBanner';
import AmbedkarScreen from '../../components/ambedkarPage';
import MapScreen from '../../components/mapScreen/mapScreen';
import MediaGallery from '../../components/mediaGallery/mediaGallery';
import SocialMedia from '../../components/socialMedia/socialMedia';
import Footer from '../../components/footer/footer';
import BgImageMob from '../../assets/images/landing/bgImage.png';
import BgImage from '../../assets/images/landing/Home.jpg';
import headerImage from '../../assets/images/landing/headerImage.jpg';
import { getLandingPageCount } from '../../api';
import VideoSection from '../videoSection';

const Home = () => {
  const [totalEventCount, setTotalEventCount] = useState(0);
  const [totalParticipation, setTotalParticipation] = useState(0);
  const [totalMedia, setTotalMedia] = useState(0);
  const [preambleRead, setPreambleRead] = useState(0);

  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getCount = async () => {
    const res = await getLandingPageCount();
    if (res.status === 200) {
      setTotalEventCount(res.data?.total_events);
      setTotalParticipation(res.data?.total_participation);
      setTotalMedia(res.total_media);
      setPreambleRead(res.data?.preamble_read);
    }
  };

  useEffect(() => {
    getCount();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: '100%', minHeight: '100vh', backgroundColor: '#FFF' }}>
      <Navbar />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          overflowX: 'hidden',
          position: 'relative',
          paddingTop: '56px',
        }}
      >
        <LandingBanner
          eventCount={totalEventCount}
          participationCount={totalParticipation}
          preambleRead={preambleRead}
        />

        {/* Container Box for Header and Background Images */}
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {/* Header Image */}

          <Box
            sx={{
              width: '100%',
              height: { xs: '20px', md: '80px' },
              backgroundImage: `url(${headerImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />

          {/* Section with BgImage */}
          <Box
            sx={{
              width: '100%',
              backgroundImage: window.innerWidth > 600 ? `url(${BgImage})` : `url(${BgImageMob})`,
              backgroundSize: window.innerWidth > 600 ? 'cover' : 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: window.innerWidth > 600 ? 'no-repeat' : 'repeat',

              // paddingTop: '40px',
            }}
          >
            <AmbedkarScreen showForm={false} />
            {/* <Box
              sx={{
                width: '100%',
                height: { xs: '20px', md: '80px' },
                backgroundImage: `url(${headerImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            /> */}
            {/* <MapScreen
              eventCount={totalEventCount}
              participationCount={totalParticipation}
              totalMedia={totalMedia}
            /> */}
            {/* <Box
              sx={{
                width: '100%',
                height: { xs: '20px', md: '80px' },
                backgroundImage: `url(${headerImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            /> */}

            <VideoSection />
            <MediaGallery />
            {/* <Box
              sx={{
                width: '100%',
                height: { xs: '20px', md: '80px' },
                backgroundImage: `url(${headerImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            /> */}
            <SocialMedia />
          </Box>
        </Box>

        <Footer />
      </Box>
    </div>
  );
};

export default Home;
