import React, { useState, useEffect } from 'react';
import { Typography, Button, Box, TextField, Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AmbedkarImage from '../../assets/images/landing/book.png';
import Navbar from '../../layouts/full-layout/header/homeHeader';
import BgImage from '../../assets/images/landing/bgImage.png';
import { askBabaForm } from '../../api';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import ChatBot from '../ChatBot';
import { useTranslation } from 'react-i18next';
import AmbedkarIcon from '../../assets/images/landing/ambedkarIconImage.png';
import { useNavigate } from 'react-router';

export default function AmbedkarScreen({ showForm }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [userName, setUserName] = useState('');
  const [docId, setDocId] = useState('');

  useEffect(() => {
    // Check if the device is iOS
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPhone|iPad|iPod/.test(userAgent)) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // const handleNavigate = () => {
  //   window.location.href = 'https://statuesque-biscochitos-01b3d8.netlify.app/';
  // };

  const formik = useFormik({
    initialValues: {
      name: '',
      age: '',
      gender: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Name can only contain letters and spaces')
        .max(30, 'Name cannot exceed 30 characters')
        .required('Name is required'),
      age: Yup.number()
        .typeError('Age must be a number')
        .min(1, 'Age must be greater than 0')
        .max(99, 'Age cannot exceed 2 digits')
        .required('Age is required'),
      gender: Yup.string().required('Gender is required'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const updatedValues = {
          ...values,
          type: 'web',
        };
        const res = await askBabaForm(updatedValues);
        if (res.status === 201) {
          setDocId(res.data.id);
          setIsLoading(false);
          setShowChat(true);
        } else {
          setIsLoading(false);
          enqueueSnackbar('Internal Server Error', { variant: 'error' });
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    },
  });

  const handleNavigate = () => {
    navigate('/ask-baba-sahib');
  };

  return (
    <>
      <Box
        sx={{
          padding: '20px',
          backgroundImage: showForm && `url(${BgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#7a5e34',
            fontSize: '30px',
            mt: 8,
          }}
        >
          {t('know_your_constitution')}
        </Typography>
        {showForm && (
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              // color: '#B99744',
              textAlign: 'center',
              fontSize: { sm: '0.8rem', md: '1rem' },
            }}
          >
            {t('ask_baba_sub')}
          </Typography>
        )}
        <Grid container sx={{ padding: { md: '20px' } }}>
          {/* Image Section */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Box
              component="img"
              src={AmbedkarImage}
              alt="Dr. B.R. Ambedkar"
              sx={{
                // width: '100%',
                height: isIOS ? 'auto' : 'fit-content',
                maxWidth: { xs: '80%', md: '60%' },
                borderRadius: '10px',
                marginTop: showChat || showForm ? '5%' : '0',
                objectFit: 'contain',
                // marginLeft: '15%',
              }}
            />
          </Grid>
          {!showChat && (
            <>
              {showForm ? (
                <>
                  {/* Form Section */}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ padding: '20px', marginTop: '5%', minHeight: '70vh' }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ textAlign: 'center', color: '#646464', mb: 2, fontSize: '18px' }}
                    >
                      {t('enter_your_details')}
                    </Typography>

                    <form onSubmit={formik.handleSubmit}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          marginBottom: '16px',
                        }}
                      >
                        <TextField
                          name="name"
                          placeholder={t('enter_name')}
                          variant="outlined"
                          value={formik.values.name}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 30 && /^[A-Za-z\s]*$/.test(value)) {
                              setUserName(value);
                              formik.handleChange(e);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          error={formik.touched.name && Boolean(formik.errors.name)}
                          helperText={formik.touched.name && formik.errors.name}
                          inputProps={{ maxLength: 30 }} // Enforces maximum length
                          sx={{
                            width: { xs: '100%', sm: '80%', md: '450px' },
                            marginBottom: '16px',
                            '& .MuiInputBase-root': {
                              borderRadius: '6px',
                            },
                            '& .MuiInputBase-input': {
                              textAlign: 'center',
                            },
                            backgroundColor: '#E4D9C4',
                          }}
                        />
                        <TextField
                          name="age"
                          placeholder={t('enter_age')}
                          variant="outlined"
                          value={formik.values.age}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value) && value.length < 3) {
                              // Only allow numeric input
                              formik.handleChange(e);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          error={formik.touched.age && Boolean(formik.errors.age)}
                          helperText={formik.touched.age && formik.errors.age}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Better mobile experience
                          sx={{
                            width: { xs: '100%', sm: '80%', md: '450px' },
                            marginBottom: '16px',
                            '& .MuiInputBase-root': {
                              borderRadius: '6px',
                            },
                            '& .MuiInputBase-input': {
                              textAlign: 'center',
                            },
                            backgroundColor: '#E4D9C4',
                          }}
                        />
                      </Box>

                      <Typography
                        variant="body1"
                        sx={{ textAlign: 'center', marginBottom: '8px', color: '#7a5e34' }}
                      >
                        {t('select_gender')}
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        sx={{ marginBottom: '16px' }}
                      >
                        {['male', 'female', 'others'].map((gender) => (
                          <Button
                            key={gender}
                            onClick={() => formik.setFieldValue('gender', gender)}
                            sx={{
                              width: { xs: '100px', sm: '120px', md: '140px' },
                              borderRadius: '10px',
                              color: formik.values.gender === gender ? '#fff' : '#646464',
                              backgroundColor:
                                formik.values.gender === gender ? '#7a5e34' : '#E4D9C4',
                              border: '1px solid #5A3E00',
                              '&:hover': {
                                backgroundColor: '#d1c2a6',
                              },
                            }}
                          >
                            {t(`${gender}`)}
                          </Button>
                        ))}
                      </Stack>
                      {formik.touched.gender && formik.errors.gender && (
                        <Typography
                          variant="caption"
                          sx={{ color: 'red', textAlign: 'center', display: 'block', mb: 2 }}
                        >
                          {formik.errors.gender}
                        </Typography>
                      )}

                      <Box display="flex" justifyContent="center">
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          loading={isLoading}
                          disabled={!formik.isValid || !formik.dirty}
                          sx={{
                            width: { xs: '180px', sm: '200px', md: '220px' },
                            backgroundColor: 'black',
                            color: 'white',
                            '&:hover': {
                              backgroundColor: '#333333',
                            },
                          }}
                        >
                          {t('ask_questions')}
                        </LoadingButton>
                      </Box>
                    </form>
                    <Typography
                      variant="body2"
                      sx={{ textAlign: 'center', color: '#646464', my: 2, fontSize: '12px' }}
                    >
                      {t('know_your_constitution')}
                    </Typography>
                    {/* <Typography
                      variant="caption"
                      display="block"
                      sx={{
                        textAlign: 'center',
                        color: '#82672D',
                        marginBottom: '20px',
                        marginTop: '20px', // Added margin top
                        fontSize: '15px',
                      }}
                    >
                      *{t('form_disclaimer')}
                    </Typography> */}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      justifyContent: window.innerWidth > 600 ? 'center' : 'left',
                    }}
                  >
                    {/* <Typography
                      variant="h1"
                      gutterBottom
                      sx={{
                        textAlign: window.innerWidth > 600 ? 'left' : 'center',
                        fontWeight: 'bold',
                        color: '#7a5e34',
                        marginTop: '16%',
                        marginRight: { xs: '0', md: '20%' },
                      }}
                    >
                      {t('ask_baba')}
                    </Typography> */}
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        textAlign: window.innerWidth > 600 ? 'left' : 'center',
                        color: '#000',
                        marginRight: { xs: '0', md: '20%' },
                        marginTop: '16%',
                        marginBottom: window.innerWidth > 600 && '5%',
                        paddingRight: window.innerWidth > 600 && '5%',
                        padding: window.innerWidth < 600 && '7%',
                      }}
                    >
                      {t('chat_about')}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={2}
                      justifyContent="flex-start"
                      sx={{
                        flexDirection: { xs: 'column', md: 'row' }, // Column for phones, row for larger screens
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: '#B99744',
                          color: 'black',
                          transition: 'transform 0.2s ease, background-color 0.2s ease',
                          '&:hover': {
                            bgcolor: '#9F8239',
                          },
                        }}
                        onClick={handleNavigate}
                      >
                        {t('ask_questions')}
                      </Button>
                      {/* <Button
                        variant="outlined"
                        onClick={() => navigate('/about-baba-sahib')}
                        style={{ border: '1px solid black', color: 'black' }}
                      >
                        {t('read_about_baba')}
                      </Button> */}
                    </Box>
                  </Grid>
                </>
              )}
            </>
          )}

          {showChat && (
            <Grid item xs={12} md={6} sx={{ padding: { md: '20px' } }}>
              <Box sx={{ paddingRight: window.innerWidth > 600 && '10%' }}>
                <ChatBot docId={docId} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
