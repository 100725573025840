import React, { useEffect } from 'react';
import './TryNow.css';

const FlipBookComponent = ({ selectedBookDetails }) => {
  useEffect(() => {
    // Ensure the FlipBook library is available globally
    if (window.$) {
      const $ = window.$;

      const template = {
        html: 'templates/default-book-view.html',
        links: [
          {
            rel: 'stylesheet',
            href: 'css/font-awesome.min.css',
          },
        ],
        styles: ['css/short-black-book-view.css'],
        script: 'js/default-book-view.js',
      };

      // Initialize FlipBooks
      $('#container1').FlipBook({
        pdf: selectedBookDetails.pdfLink,
        template,
      });
    }
  }, []); // Empty dependency array ensures this runs once after the component mounts

  return (
    <div style={{ backgroundColor: 'white', margin: 0, padding: 0 }}>
      <div
        className="container"
        id="container1"
        style={{
          height: '70vh',
          width: '100%',
          margin: '20px auto',
        }}
      ></div>
    </div>
  );
};

export default FlipBookComponent;
