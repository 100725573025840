import React from 'react';
import { Box, Typography } from '@mui/material';
import Footer from '../../components/footer/footer';
import Navbar from '../../layouts/full-layout/header/homeHeader';
import BgImage from '../../assets/images/landing/bgImage.png';
import { useTranslation } from 'react-i18next';

const Disclaimer = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#FFF',
        backgroundImage: `url(${BgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Navbar />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '20px',
          marginTop: '50px',
          width: '80%',
          flex: '1',
          width: '90%',
          alignSelf: 'center',
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontWeight: 'bold', color: '#333', textAlign: 'center', mt: 5 }}
        >
          DISCLAIMER
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
          Please read these Terms of Use & Privacy Policy (hereinafter referred to as "Agreements",)
          carefully before using our website constitution75.com (hereinafter referred to as "us",
          "we", or "our" "the Site") operated by the Ministry of Culture, Government of India
          ("Ministry"). These Agreements sets forth the legally binding terms and conditions for
          your use of the services or website at constitution75.com The use of this website by the
          User including prospective registered Users is solely governed by this policy and any
          policy so mentioned by terms of reference. Moving past home page, or using any of the
          services shall be taken to mean that User have read and agreed to all of the agreements or
          policies so binding upon User and that User are contracting with the Ministry and have
          undertaken binding obligations with the Ministry. User will be subject to the rules,
          guidelines, policies, terms, and conditions applicable to any service that is provided by
          this website, and they shall be deemed to be incorporated into this Terms of Use & Privacy
          Policy and shall be considered as part and parcel of these Agreements.
        </Typography>
        <br />
        <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '18px', color: '#555' }}>
          1. Scope of Information Provided
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
          The chatbot is designed to provide general information about the Indian Constitution, and
          its related topics. The responses generated are based on artificial intelligence (AI)
          technology and pre-programmed data, and while efforts are made to ensure accuracy, errors
          or omissions may occur. The information provided by the chatbot is for general
          informational purposes only and should not be considered legal, professional, or official
          advice. Users are strongly advised to verify any information received from the chatbot
          with authoritative sources and consult qualified professionals for specific guidance.
        </Typography>
        <br />
        <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '18px', color: '#555' }}>
          2. Non-Binding Nature of Information
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
          The chatbot's responses are not legally binding and cannot be relied upon as evidence in
          any judicial, quasi-judicial, or administrative proceedings. The Ministry, its developers,
          and associated entities expressly disclaim any liability for decisions made or actions
          taken based on the chatbot's responses.
        </Typography>
        <br />
        <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '18px', color: '#555' }}>
          3. Not Admissible as Evidence
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
          The information provided is not admissible as evidence in any court of law, tribunal, or
          similar adjudicating body. Users should treat the chatbot as a technological tool intended
          for convenience and general learning.
        </Typography>
        <br />
        <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '18px', color: '#555' }}>
          4. Limitation of Liability
        </Typography>
        <Box sx={{ fontSize: '20px', color: '#555' }}>
          <Typography variant="body1">
            The Ministry, its officials, employees, managers, affiliates, and developers shall not
            be liable for any direct, indirect, incidental, consequential, or punitive damages
            arising from:
          </Typography>
          <ul
            style={{
              marginTop: '10px',
              paddingLeft: '20px',
              color: '#555',
              fontSize: '18px',
            }}
          >
            <li>The use of the chatbot, website, or associated services.</li>
            <li>Any errors, inaccuracies, or omissions in the responses.</li>
            <li>Loss, damage, or injury caused by reliance on the chatbot's information.</li>
            <li>Inaccessibility, failure, or interruption of services.</li>
            <li>
              Ministry or its officials, managers, employees, or affiliates will not be liable for
              any loss, damage, injury, inaccessibility, failure, interruption, alteration, or
              damage of any data, third-party information, or other information caused to a user
              arising out of use of the website, its online services, or materials.
            </li>
          </ul>
        </Box>
        <br />
        <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '18px', color: '#555' }}>
          5. Use of Technology Disclaimer
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
          This chatbot operates using advanced AI technology and is subject to continuous updates
          and improvements. The chatbot is a product of technology and does not reflect the official
          position or statement of the Ministry or the Government of India.
        </Typography>
        <br />
        <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '18px', color: '#555' }}>
          6. Updates and Modifications
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
          Ministry’s hold the sole right to modify these Agreements without prior permission from
          User or informing User. The relationship creates on User a duty to periodically check the
          terms and stay updated on its requirements. If User continues to use the website following
          such a change, this is deemed as consent by User to the so amended Agreement. As long as
          User complies with these Agreements, we grant User a personal, non-exclusive,
          non-transferable, limited privilege to enter and use the website.
        </Typography>
        <br />
        <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '18px', color: '#555' }}>
          7. User Responsibility
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
          Users must independently verify any information obtained through the chatbot and
          acknowledge that it does not substitute professional legal advice or official government
          publications. Users bear full responsibility for reliance on the chatbot's responses.
        </Typography>
        <br />
        <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '18px', color: '#555' }}>
          8. Governing Law and Jurisdiction
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
          This disclaimer, the chatbot, and associated services are governed by and construed in
          accordance with the laws of India. Any disputes shall fall under the exclusive
          jurisdiction of the courts located in [Delhi], India.
        </Typography>
        <br />
        <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '18px', color: '#555' }}>
          9. Contact for Grievances
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
          In case users find any information objectionable or beyond the scope of the chatbot's
          purpose, they may contact the Grievance Redressal Officer (Mr. Rajeev Kumar) at Mobile
          No.: +91 11-2344639; Email ID: rajeev.kr37@gov.in If users are not satisfied with the
          resolution of their concerns, they may discontinue using the chatbot and website.
        </Typography>
        <br />
        <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: '18px', color: '#555' }}>
          10. Acknowledgment
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
          By using this chatbot or the associated website, users acknowledge and agree to the terms
          of this disclaimer, including all incorporated agreements, such as the Privacy Policy.
        </Typography>
      </Box>
      <Footer />
    </Box>
  );
};
export default Disclaimer;
