import React, { useEffect, useState, useRef } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { Container, Row, Col, Spinner, Carousel, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import { fetchTweetData } from '../../firebase/functions';
// import thumbnail from '../../assets/images/content/thumbnail.png';
import { Typography, Box, CircularProgress } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { getTweetIDs } from '../../utils/firebaseFuntions';

function SocialMedia() {
  const screenSize = window.innerWidth;
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // const statictweetData = [
  //   '1834097904845234564',
  //   '1833734380814033284',
  //   '1834089105501630940',
  //   '1834089105501630940',
  // ];
  const [tweetstatic, setTweetstatic] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const res = await getTweetIDs();
      if (res.length > 0) {
        setTweetstatic(res.map((o) => o.tweet_id));
      }
    };
    getData();
    // setTweetstatic(statictweetData);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: screenSize > 900 ? 4 : 1,
    slidesToScroll: screenSize > 900 ? 2 : 1,
  };

  //   const getTweetData = async () => {
  //     const data = await fetchTweetData();
  //     setTweets(data);
  //     setLoading(false);
  //   };

  // useEffect(() => {
  //   setTweetstatic(statictweetData);
  //   // getTweetData();
  // }, []);

  const carouselConfig = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: screenSize > 900 ? 4 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        // background:
        //   'linear-gradient(45deg, rgba(20, 23, 26, 1), rgba(101, 119, 134, 1), rgba(170, 184, 194, 1), rgba(225, 232, 237, 1))',
        width: '100%',
        // backdropFilter: 'blur(5px)',
        paddingBottom: { xs: '30px', sm: '30px', md: '10px' },
      }}
    >
      <div
        className="hashtag-div  "
        style={{
          paddingTop: '2%',
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: '#7a5e34',
            marginBottom: '20px',
            fontSize: '30px',
            textAlign: 'center',
          }}
        >
          {t("social_media")}
        </Typography>
      </div>

      <Container
        style={{
          marginBottom: '2%',
          display: screenSize > 768 && 'flex',
          justifyContent: 'center',
          zIndex: 22,
        }}
      >
        <Container
          style={{
            marginBottom: '2%',
            // backgroundColor: 'white',
            padding: '2px',
          }}
        >
          <Slider
            {...{
              ...carouselConfig,
              infinite: true,
              slidesToShow: 4,
              slidesToScroll: 1,
              autoplaySpeed: 2000,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            }}
          >
            {tweetstatic.map((item) => (
              <Col
                xl={3}
                lg={3}
                md={6}
                sm={12}
                key={item}
                className="hashtagImg_sm"
                // style={{
                //   display: 'flex',
                //   textAlign: 'center',
                //   justifyContent: 'center',
                // }}
              >
                <TwitterTweetEmbed
                  className="text-center justify-content-center d-flex"
                  style={{ margin: '2px' }}
                  tweetId={item}
                  placeholder={<CircularProgress />}
                  options={{
                    // cards: 'hidden',
                    hideCard: true,
                    hideThread: false,
                    maxWidth: window.innerWidth > 767 ? 200 : '80vw',
                    width: window.innerWidth > 767 ? 200 : '80vw',
                  }}
                />
              </Col>
            ))}
          </Slider>
        </Container>
      </Container>
    </Box>
  );
}

export default SocialMedia;
